<template>
  <div class="journalism">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <!-- <div class="box">
      <div class="website">网站建设中</div>
    </div> -->
    <div class="journalism_box">
      <div class="left">
        <h3>新闻动态</h3>
        <div
          v-for="item in navList"
          :key="item.id"
          @click="onNav(item.id)"
          :class="active === item.id ? 'navActive' : ''"
        >
          {{ item.newsType }}
        </div>
      </div>
      <div class="right" v-show="list.length">
        <div v-for="item in list" :key="item.id" class="item">
          <div class="time" @click="toDetails(item)">
            <h4>{{ item.newsDate.slice(-2) }}</h4>
            <span>{{ item.newsDate.slice(0,7) }}</span>
          </div>
          <div class="content" @click="toDetails(item)">
            <h3>{{ item.newsTitle }}</h3>
            <div v-html="item.newsContent"></div>
          </div>
        </div>
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagingData.pageNum"
          :page-sizes="[3, 5, 10, 20]"
          :page-size="pagingData.pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <div class="right_empty" v-show="!list.length">
        <div class="website">没有数据</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { newsTypeList, newsInformationList } from '../api/index'
export default {
  name: "Journalism",
  data() {
    return {
      navList: null,
      active: 1,
      list: [],
      pagingData: {
        pageNum: 1,
        pageSize: 3,
      },
      total:0,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/7.png"),
        },
      ],
    };
  },
  created() {
    this.loadNewsTypeList()
    this.list.map((item, index) => {
      this.list[index].dates = item.date.split("-");
    });
  },
  mounted() {},
  methods: {
    loadNewsTypeList() {
      newsTypeList().then(res => {
        this.navList = res
        this.onNav(res[0].id)
      })
    },
    // 选择新闻动态
    onNav(index) {
      this.active = index;
      this.loadNewsInformationList()
    },
    handleSizeChange(val) {
      this.pagingData.pageSize = val;
      this.loadNewsInformationList()
    },
    handleCurrentChange(val) {
      this.pagingData.pageNum = val;
      this.loadNewsInformationList()
    },
    // 加载新闻
    loadNewsInformationList() {
      newsInformationList({
        newsType:this.active,
        ...this.pagingData
      }).then(res => {
        console.log(res,'动态新闻');
        if (res.code == 200) {
          this.list = res.rows
          this.total = res.total
        }
      })
    },
    toDetails(item) {
      console.log(item);
      this.$router.push({
        name:"JournalismDetails",
        query: item
      });
    },
  },
};
</script>

<style scoped lang="scss">
.journalism {
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .website {
    height: 300px;
    font-size: 30px;
    line-height: 300px;
    color: #aaa;
  }
  .journalism_box {
    padding: 80px 150px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 245px;
      box-shadow: 0px 0px 5px #eeeeee;
      margin-right: 40px;
      > h3 {
        padding-left: 40px;
        color: #fff;
        font-size: 25px;
        background-color: #006eff;
        line-height: 80px;
        text-align: left;
        font-weight: 400;
      }
      > div {
        font-size: 20px;
        color: #333;
        line-height: 60px;
        font-weight: 200;
        cursor: pointer;
      }
      .navActive {
        color: #006eff;
      }
    }
    .right {
      width: 100%;
      .item {
        border-bottom: 1px solid #eeeeee;
        display: flex;
        max-height: 150px;
        padding-bottom: 30px;
        margin-bottom: 40px;
        .time {
          min-width: 110px;
          height: 110px;
          border: 1px solid #eeeeee;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 30px;
          cursor: pointer;
          h4 {
            font-weight: 400;
            font-size: 25px;
            color: #333;
            font-family: "AlibabaPuHuiTi-Regular";
            line-height: 40px;
          }
          span {
            font-size: 18px;
            color: #666;
            font-family: "AlibabaPuHuiTi-Light";
            font-weight: 200;
            line-height: 40px;
          }
        }
        .content {
          cursor: pointer;
          h3 {
            font-weight: 400;
            font-size: 25px;
            line-height: 40px;
            color: #333;
            text-align: left;
            margin-bottom: 10px;
          }
          div {
            text-align: left;
            line-height: 40px;
            font-weight: 200;
            font-size: 20px;
            color: #666666;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3; /* 这里是超出几行省略 */
          }
        }
      }
      .item:hover {
        .content {
          h3 {
            color: #006eff;
          }
        }
      }
      ::v-deep .el-pagination {
        .el-pagination__total,
        .number,
        .el-icon {
          font-size: 18px;
          font-family: "AlibabaPuHuiTi-Light";
          font-weight: 200;
        }
        .number,
        .btn-prev,
        .btn-next {
          width: 32px;
          height: 32px;
          line-height: 32px;
          background-color: #fff;
          box-sizing: border-box;
          border: 1px solid #d9d9d9;
          border-radius: 6px;
        }
        .el-pagination__total {
          line-height: 32px;
        }
        .active {
          color: #fff;
          background-color: #1890ff;
        }
        .el-pagination__sizes .el-select .el-input {
          width: 108px;
          .el-input__inner {
            line-height: 32px;
            height: 32px;
            font-size: 18px;
            color: #666;
            font-weight: 200;
            font-family: "AlibabaPuHuiTi-Light";
          }
          .el-select__caret {
            font-size: 18px;
            line-height: 32px;
          }
        }
        .el-pagination__jump {
          line-height: 32px;
          height: 32px;
          font-size: 18px;
          color: #666;
          font-weight: 200;
          .el-input {
            .el-input__inner {
              width: 48px;
              line-height: 32px;
              height: 32px;
              font-size: 18px;
              color: #666;
              font-weight: 200;
              font-family: "AlibabaPuHuiTi-Light";
            }
          }
        }
      }
    }
    .right_empty {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .website {
        height: 300px;
        font-size: 30px;
        line-height: 300px;
        color: #aaa;
      }
    }
  }
}
</style>
